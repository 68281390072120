import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import Loader from '../components/Loader';
import {
  adminAtom,
  merchantAtom,
  posDeviceToCreateAtom,
  posDevicesAtom,
  selectedStoreAtom,
} from '../recoil/atoms';
import { paths } from '../utils/constants';
import { CreatePosDevice } from '@bofrak-backend/shared';
interface AddPosDeviceProps {
  onClose: () => void;
}

function AddPosDevice({ onClose }: AddPosDeviceProps) {
  const admin = useRecoilValue(adminAtom);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [posDeviceToCreate, setPosDeviceToCreate] = useRecoilState(
    posDeviceToCreateAtom,
  );

  const posDevicePage = useRecoilValue(posDevicesAtom);
  const toast = useToast();
  const merchant = useRecoilValue(merchantAtom);

  useEffect(() => {
    if (!posDeviceToCreate) {
      setPosDeviceToCreate({
        name: '',
        store_id: '',
        activated: false,
        merchant_id: '',
        prefix: '',
        mac_address: '',
      });
    }
  }, [posDeviceToCreate, setPosDeviceToCreate, merchant]);

  const { mutateAsync, isLoading } = useMutation(
    (data: CreatePosDevice) => apiAdapter.createPOSDevice(data),
    {
      onSuccess: (data) => {
        if (!data)
          console.log('No data returned from create POS device mutation');
        onClose();
        queryClient.invalidateQueries('get-pos-devices');
        setPosDeviceToCreate(null);
        navigate(paths.posDevices);
      },
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: 'Could not create POS Device',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (!admin) {
      navigate(paths.home);
    }
  }, []);

  if (!posDeviceToCreate || !merchant) return <Loader />;

  return (
    // <Main>
    <Stack
      w={'full'}
      justifyContent={'center'}
      alignItems={'center'}
      h={'auto'}
      pb={'2'}
      gap={'2'}>
      {/* Form Heading */}
      <Heading textAlign="center" fontSize={'lg'}>
        Add POS Device
      </Heading>

      <FormControl>
        {/* Device Name Field */}
        <FormLabel fontWeight={'bold'}>Name</FormLabel>
        <Input
          onChange={(e) =>
            setPosDeviceToCreate({
              ...posDeviceToCreate,
              name: e.target.value,
            })
          }
          value={posDeviceToCreate.name}
          type="text"
        />

        {/* Prefix Field */}
        <FormLabel fontWeight={'bold'} mt={5}>
          Prefix
        </FormLabel>
        <Input
          value={posDeviceToCreate.prefix}
          type="text"
          onChange={(e) => {
            // Check if the prefix exists already
            if (
              posDevicePage?.pos_devices.some(
                (device) => device.prefix === e.target.value,
              )
            ) {
              toast({
                title: 'Prefix already exists',
                description: 'Please enter a unique prefix',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              return;
            }

            setPosDeviceToCreate({
              ...posDeviceToCreate,
              prefix: e.target.value,
            });
          }}
        />

        {/* Inactive Toggle */}
        {/* <FormLabel fontWeight={'bold'} mt={5}>
                Inactive
              </FormLabel>
              <Switch
                size="lg"
                onChange={(e) =>
                  setPosDeviceToCreate({
                    ...posDeviceToCreate,
                    activated: e.target.checked,
                  })
                }
                isChecked={!!posDeviceToCreate?.activated}
              /> */}
      </FormControl>

      {/* Action Buttons */}
      <HStack w={'full'}>
        <Button
          width={'full'}
          size={'sm'}
          colorScheme={'gray'}
          onClick={() => navigate(paths.posDevices)}>
          Back
        </Button>
        <Button
          width={'full'}
          colorScheme={'blue'}
          size={'sm'}
          onClick={async () => {
            // Validate name
            if (posDeviceToCreate.name.length < 3) {
              toast({
                title: 'Invalid Device Name',
                description: 'Please enter a valid device name',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              return;
            }

            // Validate prefix
            if (
              !posDeviceToCreate.prefix ||
              posDeviceToCreate.prefix.length === 0
            ) {
              toast({
                title: 'Invalid Prefix',
                description: 'Please enter a valid prefix',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              return;
            }

            // Prepare the final device object
            setPosDeviceToCreate({
              ...posDeviceToCreate,
              store_id: currentStore?.id as string,
            });

            await mutateAsync({
              ...posDeviceToCreate,
              store_id: currentStore?.id as string,
              merchant_id: merchant?.id,
            });
          }}
          isLoading={isLoading}>
          Save
        </Button>
      </HStack>

      {/* Delete POS Link */}
      <Text
        textAlign="left"
        color="red.500"
        cursor="pointer"
        onClick={() => {
          // Handle deletion logic here
          console.log('Delete POS action');
        }}>
        Delete POS
      </Text>
    </Stack>
    // </Main>
  );
}

export default AddPosDevice;
