import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
  } from '@chakra-ui/react';
  
  interface ModalComponentProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    size?: string;
  }
  
  export default function ModalComponent({
    children,
    isOpen,
    onClose,
    size = 'lg'
  }: ModalComponentProps) {
    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} size={size}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody pb={6}>{children}</ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }
  