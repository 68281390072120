import {
  Action,
  Employee,
  Merchant,
  Resource,
  Role,
} from '@bofrak-backend/shared';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Tag,
  TagLabel,
  TagCloseButton,
  Button,
  Checkbox,
  Flex,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { getAllServices } from '../utils/functions';
import { useQuery } from 'react-query';
import Loader from './Loader';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { merchantAtom, rolesAtom } from '../recoil/atoms';
import { apiAdapter } from '../api/backend';
import ModalComponent from './resuable/Modal';

interface EditRoleProps {
  onClose: () => void;
  role: Role;
}

interface DeleteRoleProps {
  onClose: () => void;
  role: Role;
}

interface ServiceViewProps {
  serviceName: string;
  role: Role;
  checkedActions: string[];
  setCheckedActions: (checkedAction: any) => void;
}

async function getActionsInRole(roleId: string): Promise<string[]> {
  const policies = (await apiAdapter.getPoliciesInRole(roleId, 1000)).policies;
  const actions = policies.map((policy) => policy.action_id);
  return actions;
}

async function getActionsInService(serviceName: string): Promise<Action[]> {
  const resources = (await apiAdapter.getResourcesInApp(serviceName, 1000))
    .resources;
  const actionLists = await Promise.all(
    resources.map((resource: Resource) =>
      apiAdapter.getActionsInResource(resource.id, 1000),
    ),
  );
  let actionIds: string[] = [];
  actionLists.map((actionsList) => {
    actionIds = [
      ...actionIds,
      ...actionsList.actions.map((action) => action.id),
    ];
  });
  const actions = await Promise.all(
    actionIds.map((actionId) => apiAdapter.getAction(actionId)),
  );
  return actions;
}

async function handleUpdateRole(
  role: Role,
  merchant: Merchant | null,
  roleName: string,
  checkedActions: string[],
  toast: any,
  setIsEditingRole: any,
  setRoles: any,
  onClose: any,
) {
  if (!merchant) {
    return toast({
      title: 'No merchant was found!',
      status: 'error',
      duration: 4000,
      isClosable: true,
    });
  }

  setIsEditingRole(true);

  try {
    const merchantId = merchant.id;
    const [actionsInRole, allPolicies] = await Promise.all([
      getActionsInRole(role.id),
      apiAdapter
        .getPoliciesInRole(role.id, 10000)
        .then((data) => data.policies),
    ]);

    const { actionsToDelete, actionsToAdd } = getActionsDifference(
      actionsInRole,
      checkedActions,
    );
    await updatePolicies(role.id, actionsToDelete, actionsToAdd, merchantId);

    await apiAdapter.updateRoleDescription(role.id, roleName);

    await fetchAndSetRoles(merchantId, setRoles);

    toast({
      title: `Role updated successfully!`,
      description: `Role ${role.description} updated successfully!`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    });

    onClose();
  } catch (error: any) {
    console.error('Error updating role:', error);
    toast({
      title: 'Error updating role!',
      description: error.message,
      status: 'error',
      duration: 4000,
      isClosable: true,
    });
  } finally {
    setIsEditingRole(false);
  }
}

function getActionsDifference(
  actionsInRole: string[],
  checkedActions: string[],
) {
  const actionsToDelete = actionsInRole.filter(
    (actionId) => !checkedActions.includes(actionId),
  );
  const actionsToAdd = checkedActions.filter(
    (actionId) => !actionsInRole.includes(actionId),
  );
  return { actionsToDelete, actionsToAdd };
}

async function updatePolicies(
  roleId: string,
  actionsToDelete: string[],
  actionsToAdd: string[],
  merchantId: string,
) {
  const policiesToRemove = (
    await apiAdapter.getPoliciesInRole(roleId, 10000)
  ).policies.filter((policy) => actionsToDelete.includes(policy.action_id));

  await apiAdapter.removePoliciesFromRole(
    roleId,
    policiesToRemove.map((policy) => policy.id),
  );

  const actions = await Promise.all(
    actionsToAdd.map((actionId) => apiAdapter.getAction(actionId)),
  );
  const policies = await Promise.all(
    actions.map((action) =>
      apiAdapter.createPolicy(
        action.description,
        merchantId,
        action.id,
        action.resource_id,
      ),
    ),
  );

  await apiAdapter.addPoliciesToRole(
    roleId,
    policies.map((policy) => policy.id),
  );
}

async function fetchAndSetRoles(merchantId: string, setRoles: any) {
  const roles = (await apiAdapter.getRoles(merchantId, 1000)).roles;
  setRoles(() => roles);
}

function DeleteRole({ onClose, role }: DeleteRoleProps) {
  const [canDelete, setCanDelete] = useState(false);
  const merchant = useRecoilValue(merchantAtom);
  const setRoles = useSetRecoilState(rolesAtom);
  const toast = useToast();

  const deleteRole = async (e: any) => {
    const merchantId = merchant?.id;
    if (merchantId) {
      setCanDelete(false);
      await apiAdapter.deleteRole(role.id);
      const roles = (await apiAdapter.getRoles(merchantId, 10000)).roles;
      setRoles(roles);
      toast({
        title: `Role deleted successfully!`,
        description: `Role ${role.description} deleted successfully!`,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setCanDelete(true);
      onClose();
    }
  };
  return (
    <Flex align="center" justify="center" width={'100%'}>
      <Box maxW="100%" w="full" bg="white">
        <Heading size="md" color={'gray.700'} textAlign="center" my={4}>
          Are you sure you want to delete this role?
        </Heading>
        <Stack spacing={3}>
          <Text textAlign="center">
            Type{' '}
            <u>
              <i>'{role.description}'</i>
            </u>{' '}
            to delete
          </Text>
          <Input
            onChange={(e) => setCanDelete(e.target.value === role.description)}
            placeholder={role.description}
          />
          <Button
            colorScheme="red"
            w="full"
            onClick={deleteRole}
            isDisabled={!canDelete}>
            Delete
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
}

function ServiceView(props: ServiceViewProps) {
  const { serviceName, checkedActions, setCheckedActions } = props;

  const [actionsInService, setActionsInService] = useState<Action[]>();

  const { isLoading } = useQuery({
    queryKey: `${serviceName}-actions`,
    queryFn: () => getActionsInService(serviceName),
    onSuccess: (data) => setActionsInService(data),
  });

  if (isLoading) return <Loader />;

  const onCheckBox = (e: any, actionId: string) => {
    if (e.target.checked) {
      setCheckedActions([...checkedActions, actionId]);
    } else {
      setCheckedActions(checkedActions.filter((id) => id !== actionId));
    }
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Flex
            as="span"
            flex="1"
            textAlign="left"
            justifyContent="space-between">
            <Text>{serviceName}</Text>
            <Text>
              {
                actionsInService?.filter((action) => {
                  if (action) {
                    return checkedActions.includes(action.id);
                  }
                  return false;
                }).length
              }{' '}
              selected action(s)
            </Text>
          </Flex>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        {actionsInService?.map((action) => (
          <Flex gap={10}>
            <Checkbox
              onChange={(e) => onCheckBox(e, action.id)}
              isChecked={checkedActions.includes(action.id)}
            />
            <Box>{action.description}</Box>
          </Flex>
        ))}
        {actionsInService?.length == 0 && <Text>No Actions To Show</Text>}
      </AccordionPanel>
    </AccordionItem>
  );
}

export default function EditRole({ onClose, role }: EditRoleProps) {
  const [roleName, setRoleName] = useState(role.description);
  const [checkedActions, setCheckedActions] = useState<string[]>([]);
  const setRoles = useSetRecoilState(rolesAtom);
  const merchant = useRecoilValue(merchantAtom);
  const [isEditingRole, setIsEditingRole] = useState(false);
  const { isLoading } = useQuery({
    queryKey: `${role.id}-checked-actions`,
    queryFn: () => getActionsInRole(role.id),
    onSuccess: (data) => setCheckedActions(data),
  });

  const [employees, setEmployees] = useState<Employee[]>([]);

  const { isLoading: isPrincipalsLoading } = useQuery({
    queryKey: `employees-in-role-${role.id}`,
    queryFn: async () =>
      (await apiAdapter.getPrincipalsInRole(role.id, 1000)).principals,
    onSuccess: async (principals) => {
      const fetchedEmployees: Employee[] = [];
      for (let principal of principals) {
        const employee = await apiAdapter.getUser(principal.id);
        fetchedEmployees.push(employee);
      }
      setEmployees(fetchedEmployees);
    },
  });

  const {
    isOpen: isDeleteRoleModalOpen,
    onOpen: onDeleteRoleModalOpen,
    onClose: onDeleteRoleModalClose,
  } = useDisclosure();

  const toast = useToast();

  if (isLoading) return <Loader />;
  if (isPrincipalsLoading) return <Loader />;

  const serviceNames = getAllServices();

  return (
    <Flex align="center" justify="center" width={'100%'}>
      <Box maxW="100%" w="full" bg="white">
        <Heading size="md" color={'gray.700'} textAlign="center" my={4}>
          Edit Role
        </Heading>
        <Stack spacing={4} width={'100%'}>
          <InputGroup>
            <Input
              placeholder="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
            />
          </InputGroup>
          <Accordion allowToggle>
            {serviceNames.map((serviceName: string, key: number) => (
              <ServiceView
                serviceName={serviceName}
                key={key}
                role={role}
                checkedActions={checkedActions}
                setCheckedActions={setCheckedActions}
              />
            ))}
          </Accordion>
          <Flex gap={2} justifyContent="center">
            {employees.map((employee) => (
              <Tag>
                <TagLabel>{employee.name}</TagLabel>
                <TagCloseButton></TagCloseButton>
              </Tag>
            ))}
          </Flex>
          <Button
            colorScheme="blue"
            w="full"
            onClick={(e) =>
              handleUpdateRole(
                role,
                merchant,
                roleName,
                checkedActions,
                toast,
                setIsEditingRole,
                setRoles,
                onClose,
              )
            }
            isLoading={isEditingRole}>
            Save
          </Button>
          {!isEditingRole && (
            <Button
              colorScheme="red"
              w="full"
              onClick={() => onDeleteRoleModalOpen()}>
              Delete
            </Button>
          )}
          <ModalComponent
            isOpen={isDeleteRoleModalOpen}
            onClose={onDeleteRoleModalClose}>
            <DeleteRole onClose={onDeleteRoleModalClose} role={role} />
          </ModalComponent>
        </Stack>
      </Box>
    </Flex>
  );
}
