import { Store } from '@bofrak-backend/shared';
import { NavBar, useAuth } from '@bofrak-backend/shared-ui';
import { Center, Flex, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { Router } from './Router';
import { apiAdapter } from './api/backend';
import Loader from './components/Loader';
import { adminAtom, merchantAtom, selectedStoreAtom } from './recoil/atoms';
import { envVars, paths } from './utils/constants';

function App() {
  const toast = useToast();
  const [admin, setAdmin] = useRecoilState(adminAtom);
  const [merchant, setMerchant] = useRecoilState(merchantAtom);
  const {
    employee,
    signOut,
    currentStore: selectedStore,
  } = useAuth({
    applicationName: merchant?.business_name ?? '',
    stage: envVars.STAGE,
  });

  const [, setStores] = useState<Store[]>([]);
  const [currentStore, setCurrentStore] = useRecoilState(selectedStoreAtom);

  const { isLoading: merchantsLoading, refetch } = useQuery(
    ['get-merchant', { merchant_id: selectedStore?.merchant_id }],
    () => apiAdapter.getMerchant(selectedStore?.merchant_id as string),
    {
      enabled: !!selectedStore,
      onSuccess: (data) => {
        if (data) {
          setMerchant(data);
        } else {
          toast({
            title: 'No Merchant Information on this account',
            description: 'Please contact your administrator',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          // Wait for 9 seconds before signing out
          setTimeout(() => {
            signOut();
          }, 9000);
        }
      },
      onError: (error: any) => {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    },
  );

  const { refetch: refetchStores } = useQuery(
    ['get-stores', { merchant_id: employee?.merchant_id }],
    () => apiAdapter.getStores(employee?.merchant_id ?? ''),
    {
      enabled: !!employee,
      onSuccess: (data) => {
        if (data) {
          setStores(data.stores);
        }
      },
    },
  );

  useEffect(() => {
    if (employee && !admin) {
      setAdmin(employee);
      refetch();
      refetchStores();
    }

    if (selectedStore) {
      setCurrentStore(selectedStore);
    }
  }, [employee, selectedStore]);

  if (merchantsLoading) return <Loader />;

  return employee ? (
    <Flex
      direction={{
        base: 'column',
      }}
      width={'100%'}
      maxW={'100%'}
      minH={'100vh'}
      bg={'light-background'}>
      <NavBar
        appName="Settings"
        homePath={paths.home}
        merchant={merchant?.business_name ? merchant.business_name : ''}
        signOut={signOut}
        stage={envVars.STAGE}
        setCurrentStore={setCurrentStore}
        storeId={currentStore?.id as string}
        user={employee}
        merchantId={merchant ? merchant.id : ''}
      />
      {merchant && merchant?.id && (
        <Center mt={4}>
          <Text color={'gray.600'} fontWeight={'bold'}>
            {merchant?.business_name}
          </Text>
        </Center>
      )}
      {admin && <Router />}
    </Flex>
  ) : null;
}

export default App;
