import {
  AuthType,
  Employee,
  Principal,
  Role,
  UpdateUser,
  UserRole,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UNSAFE_FetchersContext, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiAdapter } from '../../api/backend';
import { merchantAtom, rolesAtom, selectedStoreAtom } from '../../recoil/atoms';
import Loader from '../Loader';
import NewEmployee from '../NewEmployee';
import NewRole from '../NewRole';
import ModalComponent from '../resuable/Modal';
import RoleList from '../RoleList';

interface Employees {
  users: Employee[] | undefined;
}

const EmployeeList = ({ users }: Employees) => {
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [name, setName] = useState('');
  const queryClient = useQueryClient();
  const [pin, setPin] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedRole, setSelectedRole] = useState<string | null>();
  const [roles, setRoles] = useRecoilState(rolesAtom);
  const [principals, setPrincipals] = useState<Principal[]>([]);
  const merchant = useRecoilValue(merchantAtom);

  useEffect(() => {
    const loadRoles = async () => {
      if (merchant) {
        const fetchedRoles = (await apiAdapter.getRoles(merchant.id, 1000))
          .roles;
        setRoles(fetchedRoles);
      }
    };

    const loadPrincipals = async () => {
      const promises = users?.map((user) => {
        const id = user.cognito_id;
        if (id) {
          return apiAdapter.getPrincipal(id);
        }
      });
      if (promises) {
        const fetchedPrincipals = await Promise.all(promises);
        const valid = fetchedPrincipals.map((principal) => {
          if (principal) return principal;
        });
        setPrincipals(valid as Principal[]);
      }
    };

    loadRoles();
    loadPrincipals();
  }, [users]);

  useEffect(() => {
    if (selectedEmployee) {
      const role = getEmployeeRole(selectedEmployee);
      if (role) setSelectedRole(role?.id);
    }
  }, [selectedEmployee]);

  const getEmployeeRole = (employee: Employee) => {
    const principal = principals.find((principal) => {
      if (principal) {
        return principal.id === employee.cognito_id;
      } else {
        return false;
      }
    });

    if (principal) {
      const role = roles.find((role) => {
        if (role) {
          return role.id === principal.role_id;
        } else {
          return false;
        }
      });
      return role;
    }

    const defaultRole = employee.roles[0];
    const roleToReturn: any = {};
    if (defaultRole) {
      roleToReturn.description = defaultRole;
    } else {
      roleToReturn.description = 'no role';
    }
    return roleToReturn as Role;
  };

  const handleEditUser = (employee: Employee) => {
    setSelectedEmployee(employee);
    setName(employee.name);
    onOpen();
  };

  const { isLoading, mutateAsync: updateUserMUtation } = useMutation(
    'updateUser',
    // Update
    (data: UpdateUser) => apiAdapter.updateUser(data),
    {
      onSuccess: (data) => {
        if (data?.id) {
          toast({
            title: 'Employee updated successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          queryClient.invalidateQueries('get-users');
          onClose();
        }
      },
      onError: (error) => {
        toast({
          title: 'An error occurred',
          description: `${error}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  const handleSave = () => {
    if (!selectedEmployee) return;
    const assignRole = async () => {
      const eId = selectedEmployee.cognito_id as string;
      const merchantId = merchant?.id as string;
      if (selectedRole) {
        // check if the principal exists, if not, create it
        const principal = await apiAdapter.getPrincipal(eId);

        if (!principal) {
          await apiAdapter.createPrincipal(eId, merchantId);
        }
        await apiAdapter.assignRole(eId, selectedRole);
        // const roles = (await apiAdapter.getRoles(merchantId, 1000)).roles;
        // setRoles(roles);
      }
    };

    updateUserMUtation({
      id: selectedEmployee.id,
      ...(name && { name }),
      roles: [],
      auth_type: AuthType.Merchant,
      ...(pin && { PIN: pin }),
    });

    assignRole();
  };
  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          Role
        </Text>
        <Text fontWeight="bold" flex={1} textAlign="right">
          Active Since
        </Text>
      </Flex>

      {/* Employee Rows */}
      {users?.map((employee, index) => (
        <Flex
          key={index}
          bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
          p={4}
          align="center"
          fontSize="sm"
          borderBottom={
            index === users?.length - 1 ? 'none' : '1px solid #E2E8F0'
          }
          my={3}
          onClick={() => handleEditUser(employee)}
          cursor="pointer">
          <Text flex={1} fontWeight="500" fontSize="sm">
            {employee.name}
          </Text>
          <Text flex={1}>
            {getEmployeeRole(employee)?.description ?? 'loading...'}
          </Text>
          <Text flex={1} textAlign="right">
            {dayjs(employee?.created_at).format('DD MMM, YYYY')}
          </Text>
        </Flex>
      ))}

      {/* Edit User Modal */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width={'full'}>
          <Text color={'gray.700'} fontWeight={'bold'}>
            Edit Employee
          </Text>
        </Center>
        <Flex direction={'column'} p={2} gap={3}>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="Pin"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </Flex>
        <RadioGroup onChange={setSelectedRole} value={selectedRole as string}>
          <Flex
            direction={'column'}
            p={2}
            gap={3}
            align={'center'}
            width={'full'}>
            <Text>Roles:</Text>
            {roles?.map((role, index) => (
              <Flex
                key={role.description}
                justify={'space-between'}
                bg={'gray.50'}
                p={2}
                align={'start'}
                width={'full'}>
                <Text color={'gray.600'} width={'50%'} fontSize={'sm'}>
                  {role.description}
                </Text>
                <Radio
                  key={index}
                  value={role.id}
                  // isChecked={selectedRole === role.id}
                  // onChange={() => setSelectedRole(role.id)}
                ></Radio>
              </Flex>
            ))}
          </Flex>
        </RadioGroup>
        <Flex justify={'flex-end'} mt={4}>
          <Button
            isLoading={isLoading}
            isDisabled={!name || !selectedRole}
            colorScheme="blue"
            onClick={handleSave}>
            Save
          </Button>
        </Flex>
      </ModalComponent>
    </Box>
  );
};

const EmployeeSettings = () => {
  const navigate = useNavigate();
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  // const [usersPage, setUsersPage] = useRecoilState(usersAtom);
  const [employeeList, setEmployeeList] = useState<Employee[] | undefined>([]);

  const {
    isOpen: isUsersModalOpen,
    onOpen: onUsersModalOpen,
    onClose: onUsersModalClose,
  } = useDisclosure();

  const {
    isOpen: isRolesModalOpen,
    onOpen: onRolesModalOpen,
    onClose: onRolesModalClose,
  } = useDisclosure();

  const { data, isLoading } = useQuery(
    ['get-users', { merchant_id: merchant?.id }],
    () => apiAdapter.getUsers({ merchant_id: merchant?.id ?? '', limit: 250 }),
    {
      enabled: !!merchant?.id,
    },
  );

  useEffect(() => {
    if (data?.employees) {
      //  filter users where the usersPage.stores includes the currentStore?.id
      const employees = data?.employees?.filter((user: Employee) =>
        user?.stores?.includes(currentStore?.id as string),
      );
      setEmployeeList(employees);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>

      <Tabs isManual variant="enclosed" width="100%">
        <TabList
          // Make the TabList sticky
          position="sticky"
          top={0}
          zIndex={1}
          bg="gray.50"
          boxShadow="md"
          padding={'0'}>
          <Tab width="50%">Employee List</Tab>
          <Tab width="50%">Roles</Tab>
        </TabList>

        <TabPanels>
          {/* Employee List Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onUsersModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Employee
              </Button>
            </Flex>

            <EmployeeList users={employeeList} />
          </TabPanel>

          {/* Roles Panel */}
          <TabPanel
            width={'full'}
            style={{
              padding: '0',
            }}>
            <Flex justify={'flex-end'} align="center">
              <Button
                size="sm"
                onClick={onRolesModalOpen}
                colorScheme="blue"
                my={4}>
                + Add Role
              </Button>
            </Flex>
            <RoleList />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalComponent isOpen={isUsersModalOpen} onClose={onUsersModalClose}>
        <NewEmployee onClose={onUsersModalClose} />
      </ModalComponent>

      <ModalComponent isOpen={isRolesModalOpen} onClose={onRolesModalClose}>
        <NewRole onClose={onRolesModalClose} />
      </ModalComponent>
    </Box>
  );
};

export default EmployeeSettings;
