import {
  Flex,
  Heading,
  Stack,
  Button,
  Box,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Input,
  useToast,
} from '@chakra-ui/react';

import { getAllServices } from '../utils/functions';
import { useQuery } from 'react-query';
import { apiAdapter } from '../api/backend';
import Loader from './Loader';
import {
  Action,
  ActionList,
  Policy,
  Resource,
  ResourceList,
  Role,
} from '@bofrak-backend/shared';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  actionsAtom,
  checkedActionsAtom,
  merchantAtom,
  rolesAtom,
} from '../recoil/atoms';
import { useState } from 'react';

interface NewRoleProps {
  onClose: () => void;
  storeId?: string | undefined;
}

interface ResourcesViewProps {
  serviceName: string;
}

interface ResourceViewProps {
  resource: Resource;
}

interface ActionViewProps {
  action: Action;
}

const getActionsInResource = (actions: Action[], resourceId: string) => {
  const result = actions.filter(
    (action: Action) => action.resource_id == resourceId,
  );
  return result;
};

function ActionView({ action }: ActionViewProps) {
  const [checkedActions, setCheckedActions] =
    useRecoilState(checkedActionsAtom);
  const checkAction = (actionId: string) => {
    // check if the action has not already been checked
    if (!checkedActions.includes(actionId))
      setCheckedActions([...checkedActions, actionId]);
  };

  const unCheckAction = (actionId: string) => {
    setCheckedActions(
      [...checkedActions].filter((id: string) => id !== actionId),
    );
  };

  const onCheckBox = (e: any) => {
    if (e.target.checked) {
      checkAction(action.id);
    } else {
      unCheckAction(action.id);
    }
  };

  return (
    <Flex gap={10}>
      <Checkbox
        onChange={onCheckBox}
        isChecked={checkedActions.includes(action.id)}
      />
      <Box>{action.description}</Box>
    </Flex>
  );
}

function ResourceView({ resource }: ResourceViewProps) {
  const actionsAll = useRecoilValue(actionsAtom);
  const actions = getActionsInResource(actionsAll, resource.id);

  return (
    <Box>
      {actions.map((action: Action, key: number) => (
        <ActionView key={key} action={action} />
      ))}
    </Box>
  );
}

function ResourcesView({ serviceName }: ResourcesViewProps) {
  const setActions = useSetRecoilState(actionsAtom);

  const { data: resourcesList, isLoading: isResourcesLoading } =
    useQuery<ResourceList>({
      queryKey: `${serviceName}-resources`,
      queryFn: () => apiAdapter.getResourcesInApp(serviceName, 1000),
      onSuccess: async (data) => {
        const actionsLists = await Promise.all(
          data.resources.map((resource: Resource) =>
            apiAdapter.getActionsInResource(resource.id, 100),
          ),
        );
        actionsLists.map((actionsList: ActionList) =>
          actionsList.actions.map((action: Action) =>
            setActions((oldActions) => {
              if (
                !oldActions
                  .map((action: Action) => action.id)
                  .includes(action.id)
              )
                return [...oldActions, action];
              return oldActions;
            }),
          ),
        );
      },
    });

  if (isResourcesLoading) return <Loader />;

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left" fontWeight="bold">
            {serviceName}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel>
        {resourcesList?.resources.map((resource: Resource, key: number) => (
          <ResourceView key={key} resource={resource} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
}

const NewRole = ({ onClose }: NewRoleProps) => {
  const [checkedActions, setCheckedActions] =
    useRecoilState(checkedActionsAtom);
  const merchant = useRecoilValue(merchantAtom);
  const actions = useRecoilValue(actionsAtom);
  const [roleName, setRoleName] = useState('');
  const [isCreatingRole, setIsCreatingRole] = useState(false);
  const setRoles = useSetRecoilState(rolesAtom);
  const toast = useToast();
  const serviceNames = getAllServices();

  const getActionById = (actionId: string) => {
    const action = actions.filter((action: Action) => action.id === actionId);
    if (action && action.length > 0) return action[0];
  };

  const createRole = async (e: any) => {
    e.preventDefault();

    setIsCreatingRole(true);

    // generate the policies for the actions and resources
    // go through checked actions to do this
    const policyPromises = checkedActions.map((actionId: string) => {
      // get the action by its id
      const action = getActionById(actionId);
      if (action) {
        const resourceId = action.resource_id;
        // create the policy
        if (merchant)
          return apiAdapter.createPolicy(
            action.description,
            merchant.id,
            actionId,
            resourceId,
          );
      }
    });
    const policies = (await Promise.all(policyPromises)).map(
      (policy: Policy | undefined) => policy?.id,
    ) as string[];
    // create the role
    if (merchant) {
      const role = await apiAdapter.createRole(roleName, merchant.id);
      await apiAdapter.addPoliciesToRole(role.id, policies);
      setIsCreatingRole(false);
      setCheckedActions([]);
      toast({
        title: 'Role created successfully!',
        description: `Role ${roleName} has been successfully created!`,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
      setRoles((oldRoles: Role[]) => [...oldRoles, role]);
    }
    onClose();
  };

  return (
    <Flex align="center" justify="center" width={'100%'}>
      <Box maxW="100%" w="full" bg="white">
        <Heading size="md" color={'gray.700'} textAlign="center" my={4}>
          Create Role
        </Heading>
        <form
          onSubmit={createRole}
          style={{
            width: '100%',
          }}>
          <Stack spacing={4} width={'100%'}>
            <Input
              placeholder="Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
            />
            <Accordion allowToggle>
              {serviceNames.map((serviceName: string, id: number) => (
                <ResourcesView serviceName={serviceName} />
              ))}
            </Accordion>
            <Button
              // isLoading={isLoading}
              colorScheme="blue"
              w="full"
              isLoading={isCreatingRole}
              type="submit">
              Create
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};

export default NewRole;
