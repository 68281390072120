import {
  Action,
  CreatePosDevice,
  CreateThreshold,
  CreateUser,
  Employee,
  EmployeePage,
  Merchant,
  PosDevice,
  PosDevicePage,
  Resource,
  Role,
  Store,
  StorePage,
  Threshold,
} from '@bofrak-backend/shared';
import { atom } from 'recoil';
import { localPersistEffect } from './atom-effects';

export const tokenAtom = atom({
  key: 'user-token',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
});

export const adminAtom = atom<Employee | null>({
  key: 'adminAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const usersAtom = atom<EmployeePage | null>({
  key: 'usersAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedUserAtom = atom<Employee | null>({
  key: 'selectedUserAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const userToCreateAtom = atom<CreateUser | null>({
  key: 'userToCreateAtom',
  default: {
    name: '',
    phone_number: '', // 10 digits
    PIN: '',
    roles: [],
    stores: [],
    username: '', // same as phone number for now
    email: '', // shopnsmile+phone_number@protonmail.com
    merchant_id: '',
  },
  effects_UNSTABLE: [localPersistEffect],
});

export const storeIdAtom = atom<string>({
  key: 'storeIdAtom',
  default: '',
  effects_UNSTABLE: [localPersistEffect],
});

export const thresholdAtom = atom<Threshold | null>({
  key: 'thresholdAtom',
  default: null,
});

export const thresholdToCreateAtom = atom<CreateThreshold>({
  key: 'thresholdToCreateAtom',
  default: {
    min_quantity: 0,
    min_total_money: 0,
    store_id: '',
    updated_by: {} as any,
    merchant_id: '',
  },
});

export const searchQueryAtom = atom<string>({
  key: 'searchQueryAtom',
  default: '',
});

export const selectedPosDeviceAtom = atom<PosDevice | null>({
  key: 'selectedPosDevice',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const posDevicesAtom = atom<PosDevicePage | null>({
  key: 'posDevicesAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const merchantAtom = atom<Merchant | null>({
  key: 'merchantAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const posDeviceToCreateAtom = atom<CreatePosDevice | null>({
  key: 'posDeviceToCreateAtom',
  default: {
    name: '',
    store_id: '',
    activated: false,
    merchant_id: '',
    prefix: '',
    mac_address: '',
  },
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedStoreAtom = atom<Store | null>({
  key: 'selectedStoreAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const storesAtom = atom<StorePage | null>({
  key: 'storesAtom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const actionsAtom = atom<Action[]>({
  key: 'actionsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const checkedActionsAtom = atom<string[]>({
  key: 'checkedActionsAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const rolesAtom = atom<Role[]>({
  key: 'rolesAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});

export const resourcesAtom = atom<Resource[]>({
  key: 'resourcesAtom',
  default: [],
  effects_UNSTABLE: [localPersistEffect],
});
