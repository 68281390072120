import { Text, VStack, Spinner } from '@chakra-ui/react';

const Loader = () => (
  <VStack
    align={'center'}
    justify={'center'}
    width={'100%'}
    height={'100%'}
    bg={'lightBackground'}
    color={'black'}
    p={5}>
    <Text fontSize={'md'} fontWeight={'black'} color={'black'}>
      {' '}
      Please Wait!{' '}
    </Text>
    <Spinner thickness="4px" speed="0.65s" color={'black'} size="xl" />
  </VStack>
);

export default Loader;
