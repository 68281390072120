export function unStringfy(str: any) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function capitalize(str: string) {
  return str.toLocaleLowerCase().charAt(0).toUpperCase() + str.slice(1);
}

export function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(2, 2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function getAllServices() {
  const serviceNames = [
    'customers',
    'discounts',
    'inventory',
    'merchants',
    'payment-types',
    'pos-device',
    'products',
    'receipts',
    'reports',
    'stores',
    'suppliers',
    'taxes',
  ];
  return serviceNames;
}