import {
  Box,
  Button,
  Flex,
  Center,
  Text,
  useDisclosure,
  VStack,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Select,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ModalComponent from '../../resuable/Modal';
import { merchantAtom, selectedStoreAtom } from '../../../recoil/atoms';
import Loader from '../../Loader';
import { CreateTax, Tax, TaxType, UpdateTax } from '@bofrak-backend/shared';
import { useNavigate } from 'react-router-dom';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';

interface TaxesProps {
  taxes: Tax[] | undefined;
}

const TaxesList = ({ taxes }: TaxesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTax, setSelectedTax] = useState<Tax | null>(null);
  const merchant = useRecoilValue(merchantAtom);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: selectedTax?.name,
      rate: selectedTax?.rate,
      type: selectedTax?.type,
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'update-tax',
    (data: UpdateTax) => apiAdapter.updateTax(data),
    {
      onSuccess: (data) => {
        if (data) {
          onClose();
          reset();
          toast({
            title: 'Tax Updated successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: Error) => {
        toast({
          title: 'Failed to Update tax',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const handleClickTax = (tax: Tax) => {
    setSelectedTax(tax);
    reset({
      name: tax.name,
      rate: tax.rate,
      type: tax.type,
    });
    onOpen();
  };

  const onSubmit = async (data: any) => {
    if (!merchant) return;
    if (!selectedTax) return;

    const dtoObject: UpdateTax = {
      merchant_id: merchant.id,
      id: selectedTax.id,
    };

    if (data.name !== selectedTax.name) dtoObject.name = data.name;
    if (data.rate !== selectedTax.rate) dtoObject.rate = Number(data.rate);
    if (data.type !== selectedTax.type) dtoObject.type = data.type;

    try {
      await mutateAsync(dtoObject);
      queryClient.invalidateQueries('get-taxes');
    } catch (error) {
      console.log(error);
    }
  };

  if (!merchant) return <Loader />;

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          Rate
        </Text>
        <Text fontWeight="bold" flex={1}>
          Type
        </Text>
      </Flex>

      {taxes?.map((tax, index) => (
        <Flex
          key={index}
          bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
          p={4}
          align="center"
          fontSize="sm"
          onClick={() => handleClickTax(tax)}
          borderBottom={
            index === taxes.length - 1 ? 'none' : '1px solid #E2E8F0'
          }
          my={3}
          cursor="pointer">
          <Text flex={1} fontWeight="500" fontSize="sm">
            {tax.name}
          </Text>
          <Text flex={1}>{tax.rate}</Text>
          <Text flex={1} textAlign="right">
            {tax.type}
          </Text>
        </Flex>
      ))}

      {/* Modal for Editing Tax */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Tax
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter tax name" />
            </FormControl>

            <FormControl>
              <FormLabel>Rate</FormLabel>
              <Input
                {...register('rate')}
                placeholder="Enter tax rate"
                type="number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', {
                  required: 'This field is required',
                })}
                placeholder="Select tax type">
                {Object.values(TaxType).map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button type="submit" colorScheme="blue" isLoading={isLoading}>
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

interface DefaultValues {
  name: string;
  rate: number;
  type: TaxType;
}

const TaxesSettings = () => {
  const {
    isOpen: isTaxModalOpen,
    onOpen: onTaxModalOpen,
    onClose: onTaxModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [taxesPage, setTaxesPage] = useState<Tax[]>([]);
  const merchant = useRecoilValue(merchantAtom);

  const { status } = useQuery(
    ['get-taxes'],
    () =>
      apiAdapter.getTaxes({
        merchant_id: merchant?.id ?? '',
        limit: 250,
      }),
    {
      enabled: !!currentStore?.merchant_id,
      onSuccess: (data) => {
        if (data) {
          setTaxesPage(data.taxes);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DefaultValues>({
    defaultValues: {
      name: '',
      rate: 0,
      type: TaxType.INCLUDED,
    },
  });

  const { isLoading, mutateAsync } = useMutation(
    'create-tax',
    (data: CreateTax) => apiAdapter.createTax(data),
    {
      onSuccess: (data) => {
        if (data) {
          onTaxModalClose();
          reset();
          toast({
            title: 'Tax created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: Error) => {
        toast({
          title: 'Failed to create tax',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    if (!currentStore?.id) return;
    const dataObj: CreateTax = {
      name: data.name as string,
      rate: Number(data.rate),
      type: data.type as TaxType,
      merchant_id: merchant?.id as string,
    };
    try {
      await mutateAsync(dataObj);
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign={'center'} fontWeight="bold">
        Taxes
      </Text>
      <Flex justify={'flex-end'}>
        <Button size="sm" mb={4} onClick={onTaxModalOpen} colorScheme="blue">
          + Add Tax
        </Button>
      </Flex>
      {taxesPage.length > 0 ? (
        <TaxesList taxes={taxesPage} />
      ) : (
        <Center mt={4}>
          <Text>No taxes found</Text>
        </Center>
      )}

      <ModalComponent isOpen={isTaxModalOpen} onClose={onTaxModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Tax
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', {
                  required: 'This field is required',
                })}
                placeholder="Enter tax name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Rate</FormLabel>
              <Input
                {...register('rate', {
                  required: 'This field is required',
                })}
                placeholder="Enter tax rate"
                type="number"
              />
              {errors.rate && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', {
                  required: 'This field is required',
                })}
                placeholder="Select tax type">
                {Object.values(TaxType).map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default TaxesSettings;
