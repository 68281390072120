import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import ModalComponent from '../../resuable/Modal';
// import { storeIdAtom } from '../../../recoil/atoms';
import {
  CreateDiscount,
  Discount,
  DiscountType,
  UpdateDiscount,
} from '@bofrak-backend/shared';
import { useNavigate } from 'react-router-dom';
import { selectedStoreAtom } from '../../../recoil/atoms';
import Loader from '../../Loader';
import { apiAdapter } from '@bofrak-backend/shared-ui/src/utils/api';
//   import { createDiscount, getDiscounts } from '../../../api/bofrak-rest-api'; // Assume discount API functions exist

interface DiscountsProps {
  discounts: Discount[] | undefined;
}

const DiscountsList = ({ discounts }: DiscountsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedDiscount, setSelectedDiscount] = useState<Discount | null>(
    null,
  );
  const queryClient = useQueryClient();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const toast = useToast();

  const discountTypes = Object.values(DiscountType);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      discount_amount: 0,
      type: '',
    },
  });

  const handleClickDiscount = (discount: Discount) => {
    setSelectedDiscount(discount);
    reset({
      name: discount.name,
      discount_amount: discount.discount_amount,
      type: discount.type,
    });
    onOpen();
  };

  const { mutateAsync: updateDiscountMutation, isLoading: isUpdatingDiscount } =
    useMutation(
      'update-discount',
      (data: UpdateDiscount) => apiAdapter.updateDiscount(data),
      {
        onSuccess: (data) => {
          if (data) {
            onClose();
            queryClient.invalidateQueries('get-discounts');
            toast({
              title: 'Discount updated successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        },
        onError: (error: Error) => {
          toast({
            title: 'Failed to update discount',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          console.error(error);
        },
      },
    );

  const onSubmit = async (data: any) => {
    const dtoObject = {
      id: selectedDiscount?.id as string,
      ...(data.name === selectedDiscount?.name ? {} : { name: data.name }),

      ...(data.discount_amount === selectedDiscount?.discount_amount
        ? {}
        : { discount_amount: Number(data.discount_amount) }),

      ...(data.type === selectedDiscount?.type ? {} : { type: data.type }),
      store_id: currentStore?.id as string,
    };

    try {
      await updateDiscountMutation(dtoObject);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box width="full" p={4} boxShadow="md">
      {/* Header */}
      <Flex p={4} borderBottom="1px solid #E2E8F0" color="#000" fontSize="sm">
        <Text fontWeight="bold" flex={1}>
          Name
        </Text>
        <Text fontWeight="bold" flex={1}>
          Amount
        </Text>
        <Text fontWeight="bold" flex={1}>
          Type
        </Text>
      </Flex>

      {discounts?.map((discount, index) => (
        <Flex
          key={index}
          bg={index % 2 === 0 ? '#F7FAFC' : 'white'}
          p={4}
          align="center"
          fontSize="sm"
          onClick={() => handleClickDiscount(discount)}
          borderBottom={
            index === discounts.length - 1 ? 'none' : '1px solid #E2E8F0'
          }
          my={3}
          cursor="pointer">
          <Text flex={1} fontWeight="500" fontSize="sm">
            {discount.name}
          </Text>
          <Text flex={1}>{discount.discount_amount}</Text>
          <Text flex={1} textAlign="right">
            {discount.type}
          </Text>
        </Flex>
      ))}

      {/* Modal for Editing Discount */}
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Update Discount
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} placeholder="Enter discount name" />
            </FormControl>

            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                {...register('discount_amount')}
                placeholder="Enter discount amount"
                type="number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select {...register('type')} placeholder="Select discount type">
                {discountTypes.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button
                isLoading={isUpdatingDiscount}
                type="submit"
                colorScheme="blue">
                Update
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

const DiscountsSettings = () => {
  const {
    isOpen: isDiscountModalOpen,
    onOpen: onDiscountModalOpen,
    onClose: onDiscountModalClose,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [discountsPage, setDiscountsPage] = useState<Discount[]>([]);
  const queryClient = useQueryClient();

  const discountTypes = [
    DiscountType.FIXED_AMOUNT,
    DiscountType.FIXED_PERCENT,
    DiscountType.VARIABLE_AMOUNT,
    DiscountType.VARIABLE_PERCENT,
    DiscountType.DISCOUNT_BY_POINTS,
  ];

  const { status } = useQuery(
    ['get-discounts'],
    () =>
      apiAdapter.getDiscounts({
        store_id: currentStore?.id ?? '',
        limit: 250,
      }),
    {
      enabled: !!currentStore?.id,
      onSuccess: (data) => {
        if (data) {
          setDiscountsPage(data.discounts);
        }
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      amount: 0,
      type: '',
    },
  });

  const selectedType = watch('type');

  const { isLoading, mutateAsync } = useMutation(
    'create-discount',
    (data: CreateDiscount) => apiAdapter.createDiscount(data),
    {
      onSuccess: (data) => {
        if (data) {
          onDiscountModalClose();
          queryClient.invalidateQueries('get-discounts');
          reset();
          toast({
            title: 'Discount created successfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onError: (error: Error) => {
        toast({
          title: 'Failed to create discount',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        console.error(error);
      },
    },
  );

  const onSubmit = async (data: any) => {
    const dataObj = {
      name: data.name as string,
      discount_amount: Number(data.amount as number),
      type: data.type as DiscountType,
      store_id: currentStore?.id as string,
    };
    try {
      await mutateAsync(dataObj);
    } catch (error) {
      console.log(error);
    }
  };

  if (status === 'loading') return <Loader />;

  return (
    <Box p={4}>
      <Button size="sm" onClick={() => navigate(-1)} mb={4}>
        Back
      </Button>
      <Text fontSize="lg" textAlign={'center'} fontWeight="bold">
        Discounts
      </Text>
      <Flex justify={'flex-end'}>
        <Button
          size="sm"
          mb={4}
          onClick={onDiscountModalOpen}
          colorScheme="blue">
          + Discount
        </Button>
      </Flex>
      {discountsPage.length > 0 ? (
        <DiscountsList discounts={discountsPage} />
      ) : (
        <Center mt={4}>
          <Text>No discounts found</Text>
        </Center>
      )}

      <ModalComponent
        isOpen={isDiscountModalOpen}
        onClose={onDiscountModalClose}>
        <Center mt={10} width="full">
          <Text color="gray.700" fontWeight="bold">
            Add Discount
          </Text>
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name', { required: 'This field is required' })}
                placeholder="Enter discount name"
              />
              {errors.name && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>
                {selectedType === 'FIXED_AMOUNT'
                  ? 'Amount'
                  : selectedType === DiscountType.FIXED_PERCENT
                    ? 'Percentage'
                    : 'Amount'}
              </FormLabel>
              <Input
                {...register('amount', { required: 'This field is required' })}
                placeholder="Enter discount amount"
                type="number"
              />
              {errors.amount && (
                <Text color="red.500">This field is required</Text>
              )}
            </FormControl>

            <FormControl>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type', { required: 'This field is required' })}
                placeholder="Select discount type">
                {discountTypes.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Flex justify="flex-end" width={'full'}>
              <Button isLoading={isLoading} type="submit" colorScheme="blue">
                Add
              </Button>
            </Flex>
          </VStack>
        </form>
      </ModalComponent>
    </Box>
  );
};

export default DiscountsSettings;
