import { Box, Icon, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { FaUsersCog } from 'react-icons/fa';
import { FaShop } from 'react-icons/fa6';
import { IconType } from 'react-icons/lib';
import { MdOutlinePointOfSale } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Main from '../components/Main';
import { paths } from '../utils/constants';
import { CiReceipt } from 'react-icons/ci';
import { GrUserPolice } from 'react-icons/gr';
import { MdOutlineLoyalty, MdPayments } from 'react-icons/md';
import { TbRosetteDiscount } from 'react-icons/tb';

interface Setting {
  name: string;
  url: string;
  icon: IconType;
}

const settings: Setting[] = [
  {
    name: 'Users',
    url: paths.manageUsers,
    icon: FaUsersCog,
  },
  {
    name: 'Discounts',
    url: paths.discounts,
    icon: TbRosetteDiscount,
  },
  {
    name: 'Loyalty',
    url: paths.loyalties,
    icon: MdOutlineLoyalty,
  },
  {
    name: 'Payment Types',
    url: paths.paymentTypes,
    icon: MdPayments,
  },
  {
    name: 'Taxes',
    url: paths.taxes,
    icon: GrUserPolice,
  },
  {
    name: 'Receipts',
    url: paths?.receipts,
    icon: CiReceipt,
  },

  {
    name: 'POS Devices',
    url: paths.posDevices,
    icon: MdOutlinePointOfSale,
  },
  {
    name: 'Stores',
    url: paths.stores,
    icon: FaShop,
  },
];

function Home() {
  const navigate = useNavigate();

  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'auto'}
        pb={'2'}
        gap={'2'}>
        <SimpleGrid columns={2} spacing={5}>
          {settings.map((app) => (
            <Box
              key={app.url}
              textAlign={'center'}
              onClick={() => {
                navigate(app.url);
              }}>
              <Box
                p={4}
                cursor={'pointer'}
                height={'150px'}
                textTransform={'capitalize'}
                bg="white"
                userSelect={'none'}
                boxShadow="xl"
                borderRadius={8}>
                <Icon as={app.icon} fontSize="48px" />
                <Text fontSize={'large'} fontWeight={'bold'}>
                  {app.name}
                </Text>
              </Box>
            </Box>
          ))}
        </SimpleGrid>
      </Stack>
    </Main>
  );
}

export default Home;
