import { Route, Routes } from 'react-router-dom';
import { paths } from './utils/constants';

import AddUser from './pages/add-user';
import EditUser from './pages/edit-user';
import Home from './pages/home';
import NotFound from './pages/not-found';
import EditPosDevice from './pages/edit-pos-device';
import EmployeeSettings from './components/modals/EmployList';
import POSSettings from './components/modals/posDevice';
import TaxesSettings from './components/modals/taxes';
import DiscountsSettings from './components/modals/discounts';
import PaymentTypesSettings from './components/modals/paymentTypes';
import ManageStores from './components/modals/stores';
import LoyaltyProgram from './pages/loyalty';
import ReceiptConfirmation from './pages/receiptsConfirmation';

export const Router = () => {
  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.manageUsers} element={<EmployeeSettings />} />
      <Route path={paths.stores} element={<ManageStores />} />
      <Route path={paths.posDevices} element={<POSSettings />} />
      <Route path={paths.taxes} element={<TaxesSettings />} />
      <Route path={paths.discounts} element={<DiscountsSettings />} />
      <Route path={paths.paymentTypes} element={<PaymentTypesSettings />} />
      <Route path={paths.editPosDevice} element={<EditPosDevice />} />
      <Route path={paths.editUser} element={<EditUser />} />
      <Route path={paths.addUser} element={<AddUser />} />{' '}
      <Route path={paths.loyalties} element={<LoyaltyProgram />} />
      <Route path={paths.receipts} element={<ReceiptConfirmation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
