import {
  AuthType,
  UpdateUser,
  UserRole,
  isValidDate,
  userRolesWithoutSystem,
} from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { apiAdapter } from '../api/backend';
import Main from '../components/Main';
import { adminAtom, selectedUserAtom } from '../recoil/atoms';
import { paths } from '../utils/constants';
import { capitalize } from '../utils/functions';
import { IoIosArrowBack } from 'react-icons/io';

function EditUser() {
  const admin = useRecoilValue(adminAtom);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom);
  const [PIN, setPIN] = useState('');
  const toast = useToast();

  const { mutateAsync, isLoading } = useMutation(
    (data: UpdateUser) => apiAdapter.updateUser(data),
    {
      onSuccess: (data) => {
        if (!data) console.log('No data returned from update user mutation');
        setSelectedUser(null);
        navigate(paths.manageUsers);
      },
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: 'Could not update user',
          description: error.message ? error.message : 'Check Inputs',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    },
  );

  useEffect(() => {
    if (!admin) {
      navigate(paths.home);
    }
  }, []);

  if (!selectedUser)
    return (
      <Alert status="warning">
        No user selected, return to previous page and select
      </Alert>
    );

  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'auto'}
        pb={'2'}
        gap={'2'}>
        <Button
          onClick={() => navigate(-1)}
          leftIcon={<IoIosArrowBack />}
          variant="none"
          fontSize={'sm'}
          color={'gray.500'}
          colorScheme="blue">
          Back
        </Button>
        <Heading>Edit User</Heading>
        {!selectedUser.is_active &&
          isValidDate(selectedUser.is_inactive_since ?? '') && (
            <Alert status="warning">
              <AlertIcon />
              {`User has been inactive as since ${moment(
                selectedUser.is_inactive_since,
              ).format('DD/MM/YYYY')}`}
              .
            </Alert>
          )}
        <Card width={'full'}>
          <CardBody>
            <FormControl>
              <FormLabel fontWeight={'bold'}>Name</FormLabel>
              <Input
                onChange={(e) =>
                  setSelectedUser({ ...selectedUser, name: e.target.value })
                }
                value={selectedUser.name}
                type="text"
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                Email
              </FormLabel>
              <Input
                value={selectedUser.email}
                onChange={(e) => {
                  setSelectedUser({ ...selectedUser, email: e.target.value });
                }}
                type="email"
              />
              <FormLabel fontWeight={'bold'} mt={5}>
                Phone
              </FormLabel>
              <Input
                value={selectedUser.phone_number}
                type="text"
                onChange={(e) => {
                  setSelectedUser({
                    ...selectedUser,
                    phone_number: e.target.value,
                  });
                }}
              />

              <FormLabel fontWeight={'bold'} mt={5}>
                PIN (optional)
              </FormLabel>
              <Input
                value={PIN}
                type="number"
                placeholder="Enter PIN (4 digits)"
                onChange={(e) => {
                  setPIN(e.target.value);
                }}
              />

              <HStack mt={5}>
                <FormLabel fontWeight={'bold'} htmlFor="active-user" mb="0">
                  Active User?
                </FormLabel>
                <Spacer />
                <Switch
                  id="active-user"
                  defaultChecked={selectedUser.is_active}
                  onChange={(e) => {
                    setSelectedUser({
                      ...selectedUser,
                      is_active: e.target.checked,
                    });
                  }}
                />
              </HStack>

              <FormLabel fontWeight={'bold'} mt={5}>
                Roles
              </FormLabel>
              <CheckboxGroup
                colorScheme="green"
                defaultValue={selectedUser.roles}
                onChange={(roles) => {
                  setSelectedUser({
                    ...selectedUser,
                    roles: roles as UserRole[],
                  });
                }}>
                <Stack spacing={[1, 5]} direction={['column', 'row']}>
                  {userRolesWithoutSystem.map((role: any) => (
                    <Checkbox key={role} value={role}>
                      {capitalize(role)}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
              <HStack mt={5}>
                <Text fontSize={'sm'}>
                  Created {moment(selectedUser.created_at).fromNow()}
                </Text>
                <Spacer />
                <Text textAlign={'right'} fontSize={'sm'}>
                  Last updated {moment(selectedUser.updated_at).fromNow()}
                </Text>
              </HStack>
            </FormControl>
          </CardBody>
        </Card>
        <HStack w={'full'}>
          <Button
            width={'full'}
            colorScheme={'green'}
            onClick={() => {
              //Validate email
              if (!selectedUser.email.includes('@')) {
                toast({
                  title: 'Invalid email',
                  description: 'Please enter a valid email',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              //Validate phone number
              if (selectedUser.phone_number.length < 10) {
                toast({
                  title: 'Invalid phone number',
                  description: 'Please enter a valid phone number',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              //Validate name
              if (selectedUser.name.length < 3) {
                toast({
                  title: 'Invalid name',
                  description: 'Please enter a valid name',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }

              const updatedUser = {
                id: selectedUser.id,
                auth_type: AuthType.Merchant,
                is_active: selectedUser.is_active,
                PIN: PIN,
                stores: selectedUser.stores,
              };

              mutateAsync(updatedUser);
            }}
            isLoading={isLoading}>
            Save
          </Button>

          <Button
            width={'full'}
            colorScheme={'blue'}
            onClick={() => navigate(paths.manageUsers)}>
            Back
          </Button>
        </HStack>
      </Stack>
    </Main>
  );
}

export default EditUser;
